<template>
	<q-card>
		<q-card-section>
			Are you sure you want to stop this {{ active_tab.toUpperCase() }} scheduled message: {{ session.scenario.name }}?
		</q-card-section>
		<q-card-section v-if="session.recurrent_session_id">
			<Sliders
				:session="session"
				@update-reschedule-mode="updateRescheduleMode"
			/>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn
				flat
				label="Delete"
				color="primary"
				@click="deleteSession(session.id)"
			/>
		</q-card-actions>
	</q-card>
</template>

<script>
import Sliders from "@/components/scenarios/calendar_reports/Sliders.vue";
import SessionsAPI from "@/services/api/Sessions.js";

export default {
	name: "DeleteModal",
	components: {
		Sliders,
	},
	props: {
		session: Object,
		active_tab: String,
	},

	data() {
		return {
			message: {},
			reschedule_mode: 1,
			message_ready: false
		};
	},

	methods: {
		updateRescheduleMode(mode) {
			this.reschedule_mode = mode;
		},
		async deleteSession(session_id) {
			try {
				if (this.session.recurrent_session_id && this.reschedule_mode == 0) {
					await SessionsAPI.deleteSessionsRecurrent(session_id);
				} else {
					await SessionsAPI.deleteSession(session_id);
				}
				this.$q.notify('Successfully deleted.')
				this.$emit("close")
			} catch (error) {
				this.$q.notify({
					timeout: 6700,
					message: error.data,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$emit("close")
			}
		},
	},
};
</script>
