<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Message Calendar
		</h1>
		<SuccessErrorMessage :message="message" />
		<q-table
			:data="sessions"
			:rows-per-page-options="[10, 20, 50, 100, 0]"
			:pagination.sync="pagination"
			:columns="$q.screen.gt.xs ? columns : columns_smaller_screen"
			binary-state-sort
			@request="onRequest"
		>
			<template v-slot:top>
				<div class="flex flex-wrap items-center q-mb-md" v-if="current_CG.root_customer_group_permissions.includes('Allow Subset')">
					<q-btn icon="filter_list" @click="show_filters=true" round flat :disable="active_tab == 'real'">
						<q-tooltip anchor="top middle" :delay="500">
							Filter
						</q-tooltip>
					</q-btn>
					<span v-for="selected_value_sub in selected_values_subset" :key="selected_value_sub.label">
						<q-chip
							v-if="active_tab != 'real'"
							removable
							outline
							color="primary"
							:label="selected_value_sub.label"
							@remove="removeItem(selected_value_sub)"
						/>
					</span>
				</div>
				<div class="row full-width">
					<div class="col-md-6 col-12 items-baseline">
						<q-input
							v-model="search_by_name"
							label="Search by message name"
							outlined
							:hint="search_by_name && search_by_name.length < 3 ? 'Enter at least 3 characters' : ''"
							@input="searchByName"
						>
							<template v-slot:append>
								<q-icon name="search" />
							</template>
						</q-input>
					</div>

					<div class="col-md-6 col-12 flex justify-end items-end">
						<Tabs @open-tab="openTab" />
					</div>
				</div>
			</template>
			<template v-slot:body-cell-actions="props">
				<q-td :props="props">
					<CalendarActions
						:session="props.row"
						:active_tab="active_tab"
						@reload-sessions="getAllSessions"
					/>
				</q-td>
			</template>
			<template v-slot:body-cell-name="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:body-cell-hours="props">
				<q-td :props="props">
					{{props.value}}
					<q-tooltip anchor="top middle" :delay="500">
						{{props.value}}
					</q-tooltip>
				</q-td>
			</template>
			<template v-slot:pagination>
				<span class="row items-center justify-end">
					<q-pagination
						v-model="page_number"
						:max="number_of_pages"
						:max-pages="6"
						size="md"
						color="grey-8"
						active-color="primary"
						direction-links
						boundary-links
						@click="changePage(page_number)"
					/>
				</span>
			</template>
		</q-table>

		<q-dialog v-model="show_filters">
			<MessageFilters
				:is_invocation="false"
				:active_tab="active_tab"
				:selected_values_subset="selected_values_subset"
				@filter-sessions-by-method="filterSessionsBySubset"
				@cancel="show_filters=false"
			/>
		</q-dialog>

		<q-item class="text-left items-center q-py-none">
			You have {{ count }}
			<span v-if="active_tab != 'all'">
				&nbsp;{{ active_tab_name }}&nbsp;
			</span>
			scheduled {{ count == 1 ? 'message' : 'messages' }}
		</q-item>
		<q-fab
			class="flex justify-center q-mt-md"
			style="position: sticky; bottom: 50px; width: fit-content; right:100px;"
			:label="$q.screen.gt.sm ? 'Actions' : ''"
			label-position="left"
			color="primary"
			icon="keyboard_arrow_up"
			direction="up"
		>
			<q-fab-action
				v-if="sessions.length"
				color="primary"
				icon="o_delete"
				:label="$q.screen.gt.sm ? 'Delete ' + active_tab_name + ' messages' : ''"
				@click="show_delete_dialog = true"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Delete {{ active_tab_name }} messages
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				color="primary"
				icon="o_event_available"
				:label="$q.screen.gt.sm ? 'Check non-working days' : ''"
				@click="display_modal=true"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Check non-working days
				</q-tooltip>
			</q-fab-action>
			<q-fab-action
				v-if="logged_user.customer_permission != 'SENDER'"
				color="primary"
				icon="o_calendar_month"
				:label="$q.screen.gt.sm ? 'Schedule message' : ''"
				:to="{ name: 'Schedule Message'}"
				@click="collapse_sidebar_item({'sidebar_parent_id': 2, 'sidebar_child_id': 204})"
			>
				<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
					Schedule message
				</q-tooltip>
			</q-fab-action>
		</q-fab>
		<q-dialog v-model="display_modal">
			<CheckDates
				:sessions="sessions"
				@close="display_modal = false"
			/>
		</q-dialog>
		<q-dialog v-model="show_delete_dialog">
			<q-card>
				<q-card-section class="text-center">
					<span>
						Are you sure you want to delete
						<span v-if="active_tab == 'all'"><b> ALL </b></span>
						<span v-else> all <b>{{ active_tab_name.toUpperCase() }} </b></span>
						scheduled messages?
					</span>
				</q-card-section>
				<q-card-actions align="right">
					<q-btn flat label="Cancel" color="primary" v-close-popup />
					<q-btn
						flat
						label="Delete"
						color="primary"
						@click="deleteAllMessages"
					/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import Tabs from '@/components/scenarios/utils/Tabs.vue';
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import MessageFilters from '@/components/utils/Filters.vue';
import SessionsAPI from '@/services/api/Sessions.js';
import CheckDates from '@/components/scenarios/calendar_reports/CheckDates.vue';
import { mapActions, mapGetters } from 'vuex';
import CalendarActions from "@/components/scenarios/calendar_reports/CalendarActions.vue";
import moment from "moment-timezone";

export default {
	name: "MessageCalendar",
	meta: {
		title: 'Alert Cascade - Message Calendar'
	},
	components: {
		Tabs,
		SuccessErrorMessage,
		MessageFilters,
		CheckDates,
		CalendarActions,
	},
	data() {
		return {
			sessions: [],
			from_date: undefined,
			to_date: undefined,
			active_tab: 'test',
			page_number: 1,
			items_per_page: 10,
			count: 0,
			has_data: true,
			order_type: "ascending",
			message: {},
			subset_flags: "",
			display_modal: false,
			show_filters: false,
			selected_values_subset: [],
			sort_by: 'date_start',
			descending: true,
			show_schedule_modal: false,
			show_delete_modal: false,
			columns_smaller_screen: [
				{
					name: 'name',
					field: obj => {return obj.scenario.name},
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 150px; min-width:150px;',
					headerStyle: 'max-width: 150px; min-width:150px;',
				},
				{
					name: 'date_start',
					field: 'date_start',
					format: obj => {
						return moment.utc(obj).tz(
							this.logged_user.preferred_timezone
						).format('DD/MM/YYYY HH:mm') + ' (' + this.logged_user.preferred_timezone + ')'
					},
					align: 'left',
					label: 'Scheduled for',
					sortable: true,
				},
				{
					name: 'hours',
					field: obj => {
						return this.hoursRemaining(obj.date_start)
					},
					align: 'center',
					label: 'Hours remaining',
					classes: 'ellipsis',
					style: 'width: 100px',
					headerStyle: 'width: 100px',
				},
				{
					name: 'actions',
					align: 'center',
					label: 'Actions',
					classes: 'ellipsis',
					style: 'width: 110px',
					headerStyle: 'width: 110px',
				},
			],
			columns: [
				{
					name: 'name',
					field: obj => {return obj.scenario.name},
					align: 'left',
					label: 'Name',
					sortable: true,
					classes: 'ellipsis',
					style: 'max-width: 150px; min-width:150px;',
					headerStyle: 'max-width: 150px; min-width:150px;',
				},
				{
					name: 'dataset',
					field: obj => {return obj.scenario.customer_group.name},
					align: 'left',
					label: 'Dataset',
					classes: 'ellipsis',
					style: 'max-width: 100px; min-width:100px;',
					headerStyle: 'max-width: 100px; min-width:100px;',
					sortable: true,
				},
				{
					name: 'scheduled_by',
					field: obj => {
						if (obj.user){
							return obj.user.address_string
						}else if (obj.deleted_user_address_string){
							return obj.deleted_user_address_string
						}else{
							return '-'
						}
					},
					align: 'left',
					label: 'Scheduled by',
					sortable: true,
				},
				{
					name: 'date_start',
					field: 'date_start',
					format: obj => {
						return moment.utc(obj).tz(
							this.logged_user.preferred_timezone
						).format('DD/MM/YYYY HH:mm') + ' (' + this.logged_user.preferred_timezone + ')'
					},
					align: 'left',
					label: 'Scheduled for',
					sortable: true,
				},
				{
					name: 'hours',
					field: obj => {
						return this.hoursRemaining(obj.date_start)
					},
					align: 'center',
					label: 'Hours remaining',
					classes: 'ellipsis',
					style: 'max-width: 100px; min-width:100px;',
					headerStyle: 'max-width: 100px; min-width:100px;',
				},
				{
					name: 'actions',
					align: 'center',
					label: 'Actions',
					classes: 'ellipsis',
					style: 'max-width: 110px; min-width:110px;',
					headerStyle: 'max-width: 110px; min-width:110px;',
				},
			],
			pagination: {
				rowsPerPage: 10,
				rowsNumber: 0,
				page: 1,
			},
			search_by_name: '',
			show_delete_dialog: false,
			active_tab_name: ''
		};
	},
	computed: {
		...mapGetters(['current_CG', 'logged_user']),
		number_of_pages: function () {
			return Math.ceil(this.count / this.items_per_page);
		},
	},
	methods: {
		...mapActions(['collapse_sidebar_item']),
		async openTab(active_tab) {
			this.active_tab = active_tab;
			this.page_number = 1;
			await this.getAllSessions();
		},
		async changePage(page_number) {
			this.page_number = page_number;
			await this.getAllSessions();
		},
		async changePageNumber(pagination) {
			this.items_per_page = pagination.rowsPerPage === 0 ? this.items_per_page = this.count : pagination.rowsPerPage;
			this.sort_by = pagination.sortBy.toLowerCase()
			this.descending = pagination.descending
			this.page_number = 1;
			await this.getAllSessions();
		},
		async searchByName(){
			if (this.search_by_name.length >= 3 || this.search_by_name.length == 0){
				this.page_number = 1
				await this.getAllSessions()
			}
		},
		async getAllSessions() {
			let data = {
				'page_number': this.page_number,
				'items_per_page': this.items_per_page,
				'called_from': 'scheduled',
				'from_date': this.from_date,
				'to_date': this.to_date,
				'active_tab': this.active_tab,
				'flags': this.flags,
				'subset_flags': this.subset_flags,
				'sort_by': this.sort_by,
				'descending': this.descending,
				'search_by_name': this.search_by_name
			}
			try {
				const response = await SessionsAPI.listSessions(data);
				this.sessions = response.results;
				this.count = response.count;

				// check if there are no sessions
				this.has_data = response.count > 0;
			} catch (error) {
				if (error.data.detail == "Invalid page.") {
					this.page_number = this.page_number - 1;
					this.changePage(this.page_number);
					return;
				}
				this.message = {
					'type': 'error',
					'text': error.data
				};
			}
		},
		async filterSessionsBySubset(type, flags) {
			let value = []
			this.selected_values_subset = flags
			for (let obj of flags){
				value.push(obj.type)
			}
			this.page_number = 1;
			this.subset_flags = value;
			await this.getAllSessions();
		},
		async removeItem(item){
			let index = this.selected_values_subset.indexOf(item);
			if (index > -1) {
				this.selected_values_subset.splice(index, 1);
				await this.filterSessionsBySubset('subset', this.selected_values_subset)
			}
		},
		hoursRemaining(date_start) {
			let hours_remaining = 0
			var a = moment(); //now
			var c = a.clone().tz("UTC"); //now

			var d = moment(c.format("YYYY-MM-DDTHH:mm:ss"));

			//the date_start that comes from the api is already utc
			var b = moment(date_start, "YYYY-MM-DDTHH:mm");

			if (b.diff(d, "hours") < 0) {
				hours_remaining = 0;
			} else {
				hours_remaining = b.diff(d, "hours");
			}
			if (hours_remaining >= 1) {
				return hours_remaining;
			} else {
				return "Less than an hour";
			}
		},
		onRequest(props) {
			const { sortBy, rowsPerPage, rowsNumber, descending } = props.pagination
			this.page_number = 1
			this.pagination.rowsPerPage = rowsPerPage
			this.pagination.rowsNumber = rowsNumber
			this.pagination.sortBy = sortBy
			this.pagination.descending = descending
			this.changePageNumber(this.pagination)
		},
		async deleteAllMessages(){
			this.$q.loading.show({
				delay: 400,
			})
			this.show_delete_dialog = false
			await SessionsAPI.deleteAllSessions(this.active_tab);
			this.$q.loading.hide()
			this.$q.notify(`The messages were successfully deleted.`)
			await this.getAllSessions();
		},
		checkTabName(){
			if (this.active_tab == 'test'){
				this.active_tab_name = this.current_CG.customer_group.session_type_name.test_scenario_name
			}else if(this.active_tab == 'real'){
				this.active_tab_name = this.current_CG.customer_group.session_type_name.real_scenario_name
			}else(
				this.active_tab_name = 'All'
			)
		}
	},
	watch:{
		active_tab: function(){
			this.checkTabName();
		}
	},
	async created () {
		this.checkTabName();
		await this.getAllSessions();
	},
}
</script>
