<template>
<div>
	<q-btn
		v-if="logged_user.customer_permission != 'SENDER'"
		flat
		round
		color="dark"
		icon="o_event"
		@click="show_schedule_modal=true"
	>
		<q-tooltip anchor="top middle" :delay="500">
			Schedule
		</q-tooltip>
	</q-btn>
	<q-btn
		flat
		round
		color="dark"
		icon="o_delete"
		@click="show_delete_modal=true"
	>
		<q-tooltip anchor="top middle" :delay="500">
			Delete
		</q-tooltip>
	</q-btn>
	<q-dialog v-model="show_schedule_modal">
		<ScheduleModal
			:active_tab="active_tab"
			:session="session"
			:scenario_name="session.scenario.name"
			:recurrent_session_id="session.recurrent_session_id"
			:preferred_timezone="session.preferred_timezone"
			:show_recurrent_message_fields='false'
			@close="show_schedule_modal=false"
		/>
	</q-dialog>
	<q-dialog v-model="show_delete_modal">
		<DeleteModal
			:active_tab="active_tab"
			:session="session"
			:session_id="session.id"
			@close="show_delete_modal=false"
		/>
	</q-dialog>
</div>
</template>

<script>
import ScheduleModal from "@/components/scenarios/calendar_reports/ScheduleModal.vue";
import DeleteModal from "@/components/scenarios/calendar_reports/DeleteModal.vue";
import { mapGetters } from 'vuex';

export default {
	name: 'CalendarActions',
	components: {
		ScheduleModal,
		DeleteModal,
	},
	props: {
		session: Object,
		active_tab: String,
	},
	data(){
		return{
			show_schedule_modal: false,
			show_delete_modal: false,
		}
	},
	computed: {
		...mapGetters(['logged_user'])
	},
	watch:{
		show_schedule_modal: function(){
			if (!this.show_schedule_modal){
				this.$emit("reload-sessions");
			}
		},
		show_delete_modal: function(){
			if (!this.show_delete_modal){
				this.$emit("reload-sessions");
			}
		},
	}

};
</script>
