<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section>
			<span class="text-h6">Check if the scheduled messages contain:</span>
		</q-card-section>
		<q-card-section class="q-py-none">
			<SuccessErrorMessage :message="message" />
			<div>
				<CheckWeekendSliders
					:called_from_check="true"
					@update-values="updateValue"
				/>
			</div>

			<div>
				<HolidaySliders
					:check_holiday="check_holiday"
					:selected_country="selected_country"
					:selected_subdivision="selected_subdivision"
					:called_from_check="true"
					@update-values="updateValue"
				/>
			</div>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="closeMessages()"/>
			<q-btn color="primary" flat label="Check" @click="checkDatesList()" />
		</q-card-actions>
		<q-dialog v-model="has_messages">
			<CheckDatesMessage
				:messages="messages"
				@close="closeMessages()"
			/>
		</q-dialog>
	</q-card>
</template>
<script>
import CheckWeekendSliders from "@/components/scenarios/calendar_reports/CheckWeekendSliders.vue";
import CheckDatesMessage from '@/components/scenarios/calendar_reports/CheckDatesMessage.vue';
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import HolidaySliders from "@/components/scenarios/calendar_reports/HolidaySliders.vue";
import MessageFormsAPI from '@/services/api/MessageForm.js';
import SessionsAPI from '@/services/api/Sessions.js';
import { mapActions } from 'vuex';

export default {
	name: "CheckDates",
		components: {
		CheckWeekendSliders,
		SuccessErrorMessage,
		CheckDatesMessage,
		HolidaySliders,
		MessageFormsAPI,
		SessionsAPI,
	},
	props: {
		sessions: Array
	},
	data() {
		return {
			check_weekend: false,
			check_holiday: false,
			selected_country: '',
			selected_subdivision: '',
			has_messages: false,
			messages: [],
			show_modal: true,
			is_check_running: false,
			message: {},
		}
	},
	methods:{
		...mapActions(['updateIsCheckDatesRunning']),
		updateValue(type, value){
			this[type] = value
		},
		documentClick(e) {
			let target = e.target;
			if (
				target.className == "modal-wrapper" ||
				target.className == "modal-mask"
			) {
				this.closeMessages()
			}
		},
		closeMessages(){
			this.has_messages = false
			this.$emit('close')
		},
		validateData(){
			if (this.check_holiday && !this.selected_country){
				this.message = {
					'type': 'error',
					'text': "Please choose a country."
				}
				return false
			}
			return true
		},
		async checkDatesList() {
			if(!this.validateData()){
				return;
			}
			let response = {};
			try {
				response = await SessionsAPI.checkScheduledSession(
					this.check_weekend,
					this.check_holiday,
					this.selected_country,
					this.selected_subdivision
				)
				this.is_check_running = true
				this.$q.loading.show({
					delay: 400,
				})
				this.check_dates_init_interval = setInterval(() => {
					this.checkDatesInitResult(response.task_id);
				}, 1000);
			} catch (error) {
				let error_message = "";
				for (let key in error.data) {
					error_message += error.data[key];
				}
				this.$emit("get-message", { 'type': 'error', 'text': error_message })
				this.$q.loading.hide()
			}
		},
		async checkDatesInitResult(task_id){
			try {
				const response = await MessageFormsAPI.getRunResult(task_id);
				if (response['success'] && response['messages']) {
					clearInterval(this.check_dates_init_interval);
					this.is_check_running = false
					this.show_modal = false
					this.has_messages = true
					this.messages = response['messages']
				}
			} catch (error) {
				clearInterval(this.check_dates_init_interval);
				this.updateIsCheckDatesRunning(false);
				setTimeout(() => { this.check_dates_init_interval = null; }, 500);
			}
			this.$q.loading.hide()
		}
	},
	created() {
		document.addEventListener("click", this.documentClick);
	},
}
</script>